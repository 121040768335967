import { DialogType, useDialogStore } from '@/components/store/DialogStore';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useCreateOrganization } from '@/graphql/generated/query.types';
import { lastElement } from '@/lib/functions';
import { IconArrowRight } from '@tabler/icons-react';
import { useRouter } from 'next/router';
import { useState } from 'react';
export const CreateOrganizationDialog = () => {
  const router = useRouter();
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const activeDialog = useDialogStore(state => state.activeDialog);
  const setActiveDialog = useDialogStore(state => state.setActiveDialog);
  const cancel = () => {
    setActiveDialog(null);
  };
  const [createOrganization] = useCreateOrganization();
  const create = () => {
    createOrganization({
      variables: {
        creator: {
          name: name.trim(),
          slug: slug.trim()
        }
      }
    }).then(res => {
      const me = res.data?.createOrganization;
      if (!me) {
        return;
      }
      const organizations = me.organizations.sort((a, b) => a.timeCreated.localeCompare(b.timeCreated));
      const organization = lastElement(organizations);
      if (!organization) {
        return;
      }
      router.push(`/${organization.slug}`).catch(e => console.error(e));
    });
  };
  return <Dialog open={activeDialog === DialogType.createOrganization} onOpenChange={newValue => {
    if (!newValue && activeDialog === DialogType.createOrganization) {
      setActiveDialog(null);
    }
  }} data-sentry-element="Dialog" data-sentry-component="CreateOrganizationDialog" data-sentry-source-file="CreateOrganizationDialog.tsx">
      <DialogContent className="sm:max-w-[425px]" data-sentry-element="DialogContent" data-sentry-source-file="CreateOrganizationDialog.tsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="CreateOrganizationDialog.tsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="CreateOrganizationDialog.tsx">New organization</DialogTitle>
          <DialogDescription data-sentry-element="DialogDescription" data-sentry-source-file="CreateOrganizationDialog.tsx">Create a new organization to collaborate with colleagues.</DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right" data-sentry-element="Label" data-sentry-source-file="CreateOrganizationDialog.tsx">
              Name
            </Label>
            <Input id="name" value={name} onChange={e => setName(e.target.value)} className="col-span-3" data-sentry-element="Input" data-sentry-source-file="CreateOrganizationDialog.tsx" />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="slug" className="text-right" data-sentry-element="Label" data-sentry-source-file="CreateOrganizationDialog.tsx">
              Shortcut
            </Label>
            <Input id="slug" value={slug} onChange={e => setSlug(e.target.value)} className="col-span-3" data-sentry-element="Input" data-sentry-source-file="CreateOrganizationDialog.tsx" />
          </div>
        </div>
        <DialogFooter data-sentry-element="DialogFooter" data-sentry-source-file="CreateOrganizationDialog.tsx">
          <Button onClick={() => cancel()} variant="ghost" data-sentry-element="Button" data-sentry-source-file="CreateOrganizationDialog.tsx">
            Cancel
          </Button>
          <Button type="submit" className="flex space-x-[4px]" data-sentry-element="Button" data-sentry-source-file="CreateOrganizationDialog.tsx">
            <div>Create</div>
            <IconArrowRight size={16} data-sentry-element="IconArrowRight" data-sentry-source-file="CreateOrganizationDialog.tsx" />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>;
};