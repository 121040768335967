import { HStack } from '@/components/HStack';
import { VStack } from '@/components/VStack';
import { OrganizationContext } from '@/components/auth/OrganizationContext';
import { CreateOrganizationDialog } from '@/components/sidebar/CreateOrganizationDialog';
import { OrganizationDropdown } from '@/components/sidebar/OrganizationDropdown';
import { useSidebarStore } from '@/components/store/SidebarStore';
import { cn } from '@/lib/cn';
import { IconAt, IconBuilding, IconCreditCard, IconInbox, IconUser, IconUsersGroup } from '@tabler/icons-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext } from 'react';
export const SidebarView = () => {
  const router = useRouter();
  const organization = useContext(OrganizationContext);
  const sidebarStore = useSidebarStore();
  const topLevelButtons: {
    label: string;
    icon: JSX.Element;
    href: string;
  }[] = [{
    label: 'Inbox',
    icon: <IconInbox size={18} />,
    href: `/${organization.slug}/inbox`
  }, {
    label: 'Mentions',
    icon: <IconAt size={18} />,
    href: `/${organization.slug}/mentions`
  }];
  const settingsRows: {
    label: string;
    icon: JSX.Element;
    href: string;
  }[] = [{
    label: 'Account',
    href: `/${organization.slug}/account`,
    icon: <IconUser size={18} />
  }, {
    label: 'Organization',
    href: `/${organization.slug}/organization`,
    icon: <IconBuilding size={18} />
  }, {
    label: 'Members',
    href: `/${organization.slug}/members`,
    icon: <IconUsersGroup size={18} />
  }, {
    label: 'Billing',
    href: `/${organization.slug}/billing`,
    icon: <IconCreditCard size={18} />
  }];
  return <>
      <VStack className="w-full h-full overflow-auto px-[10px] space-y-[20px] pt-[20px]" data-sentry-element="VStack" data-sentry-source-file="SidebarView.tsx">
        <HStack className="px-[2px]" data-sentry-element="HStack" data-sentry-source-file="SidebarView.tsx">
          <OrganizationDropdown data-sentry-element="OrganizationDropdown" data-sentry-source-file="SidebarView.tsx" />
        </HStack>

        <VStack className="w-full h-fit" data-sentry-element="VStack" data-sentry-source-file="SidebarView.tsx">
          {settingsRows.map(row => {
          const isActive = router.asPath === row.href;
          return <Link key={row.href} href={row.href}>
                <HStack className={cn('space-x-[10px] h-[32px] cursor-pointer px-[6px] rounded-[6px] overflow-hidden', isActive ? 'bg-blue-500/40 dark:bg-blue-500/40' : 'bg-transparent hover:bg-primary/5')}>
                  <div className="opacity-50">{row.icon}</div>
                  <div className={cn('text-[14px] font-medium overflow-hidden whitespace-nowrap text-ellipsis', isActive ? 'text-primary/90' : 'text-primary/50')}>
                    {row.label}
                  </div>
                </HStack>
              </Link>;
        })}
        </VStack>
      </VStack>
      <CreateOrganizationDialog data-sentry-element="CreateOrganizationDialog" data-sentry-source-file="SidebarView.tsx" />
    </>;
};