import { create } from 'zustand';
export enum SidebarSection {
  Channels = 'Channels',
  Scripts = 'Scripts',
  Settings = 'Settings',
}
interface SidebarStoreState {
  openSections: SidebarSection[];
  toggleSection: (section: SidebarSection) => void;
}
export const useSidebarStore = create<SidebarStoreState>(set => ({
  openSections: [SidebarSection.Channels, SidebarSection.Scripts, SidebarSection.Settings],
  toggleSection: (section: SidebarSection) => set(state => ({
    openSections: state.openSections.includes(section) ? state.openSections.filter(s => s !== section) : [...state.openSections, section]
  }))
}));