import { SidebarView } from '@/components/sidebar/SidebarView';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import { VStack } from '@/components/VStack';
import { cn } from '@/lib/utils';
import Head from 'next/head';
export interface AppTab {
  name: string;
  href: string;
  isActive: boolean;
}
interface NewAppLayoutProps {
  pageTitle?: string;
  children?: React.ReactNode;
}
export const NewAppLayout = (props: NewAppLayoutProps) => {
  const {
    pageTitle,
    children
  } = props;
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="NewAppLayout.tsx">
        <title>Fadeaway — {pageTitle}</title>
      </Head>
      <VStack className={cn('w-full h-full overflow-hidden')} data-sentry-element="VStack" data-sentry-source-file="NewAppLayout.tsx">
        <main className="flex h-full w-full flex-row overflow-hidden">
          <ResizablePanelGroup direction="horizontal" data-sentry-element="ResizablePanelGroup" data-sentry-source-file="NewAppLayout.tsx">
            <ResizablePanel defaultSize={20} maxSize={50} minSize={10} data-sentry-element="ResizablePanel" data-sentry-source-file="NewAppLayout.tsx">
              <SidebarView data-sentry-element="SidebarView" data-sentry-source-file="NewAppLayout.tsx" />
            </ResizablePanel>
            <ResizableHandle className="bg-transparent w-[1px]" data-sentry-element="ResizableHandle" data-sentry-source-file="NewAppLayout.tsx" />
            <ResizablePanel className="bg-[#f0f0f0] dark:bg-[#151515]" data-sentry-element="ResizablePanel" data-sentry-source-file="NewAppLayout.tsx">{children}</ResizablePanel>
          </ResizablePanelGroup>
        </main>
      </VStack>
    </>;
};